
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { ActionEventArgs, AgendaService, DayService, DragAndDropService, EventSettingsModel, MonthAgendaService, MonthService, ResizeService, TimelineMonthService, TimelineViewsService, WeekService, WorkWeekService } from "@syncfusion/ej2-angular-schedule";
import { L10n, loadCldr, setCulture } from "@syncfusion/ej2-base";

// Import the required CLDR JSON files for Danish culture
import * as caGregorian from "cldr-data/main/da/ca-gregorian.json";
import * as currencies from "cldr-data/main/da/currencies.json";
import * as numbers from "cldr-data/main/da/numbers.json";
import * as timeZoneNames from "cldr-data/main/da/timeZoneNames.json";
import * as numberingSystems from "cldr-data/supplemental/numberingSystems.json";
import * as weekData from "cldr-data/supplemental/weekData.json";

// Import the required CLDR JSON files for Danish culture

@UntilDestroy()
@Component({
  selector: "app-team-scheduler",
  templateUrl: "./team-scheduler.component.html",
  styleUrls: ["./team-scheduler.component.scss"],
  providers: [DayService, WeekService, WorkWeekService, MonthService, AgendaService, MonthAgendaService, TimelineViewsService, TimelineMonthService, ResizeService, DragAndDropService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamSchedulerComponent {
  public selectedDate: Date = new Date(2024, 10, 13); // Start date of the week
  public startHour: string = "08:00"; // Work start time
  public endHour: string = "17:00"; // Work end time
  public views: Array<string> = ["TimelineWeek"]; // Define views to ensure TimelineWeek is loaded

  // Define meetings array with field names expected by Syncfusion Schedule
  public meetings: EventSettingsModel = {
    dataSource: [
      {
        Id: 1,
        Subject: "Salgsmøde",
        StartTime: new Date(2024, 10, 13, 9, 0),
        EndTime: new Date(2024, 10, 13, 11, 0),
        EmployeeId: [1, 2] // Multiple participants
      },
      {
        Id: 2,
        Subject: "Projektmøde",
        StartTime: new Date(2024, 10, 14, 13, 0),
        EndTime: new Date(2024, 10, 14, 15, 0),
        EmployeeId: [2]
      }
      // More meetings...
    ]
  };

  // Employee data with expected field names
  public employeeData = [
    { Id: 1, Name: "Thomas", Color: "#ffaa00" },
    { Id: 2, Name: "Flemming", Color: "#f8a398" }
    // More employees...
  ];

  constructor(private cd: ChangeDetectorRef) {
    // Simulate fetching data from backend
    this.fetchMeetingsFromBackend();
  }

  ngOnInit() {
    loadCldr(numberingSystems, timeZoneNames, weekData, numbers, caGregorian, currencies);

    setCulture("da");

    L10n.load({
      da: {
        schedule: {
          day: "Dag",
          week: "Uge",
          workWeek: "Arbejdsuge",
          month: "Måned",
          agenda: "Dagsorden",
          today: "I dag",
          noEvents: "Ingen begivenheder",
          allDay: "Hele dagen",
          start: "Start",
          end: "Slut",
          more: "mere",
          close: "Luk"
        }
      }
    });


  }

  // Simulate fetching data from backend
  private fetchMeetingsFromBackend(): void {
    // In reality, you would use an HTTP service to get data from your backend
    const meetingsFromBackend = [
      {
        Id: 1,
        Subject: "Salgsmøde",
        StartTime: new Date(2024, 10, 13, 9, 0),
        EndTime: new Date(2024, 10, 13, 11, 0),
        EmployeeId: [1, 2] // Multiple participants
      },
      {
        Id: 2,
        Subject: "Projektmøde",
        StartTime: new Date(2024, 10, 14, 13, 0),
        EndTime: new Date(2024, 10, 14, 15, 0),
        EmployeeId: [2]
      }
      // More meetings...
    ];

    // Assign the fetched data to the meetings array
    this.meetings.dataSource = meetingsFromBackend;
  }

  // Event resize handler
  public onEventResize(event: any): void {
    const updatedEvent = event.data;

    // Log the resized event (with new start and end times)
    console.log("Event resized:", updatedEvent);

    // Check if dataSource is an array or a DataManager
    let meetingsArray: any[] = [];

    // If dataSource is an array, use it directly
    if (Array.isArray(this.meetings.dataSource)) {
      meetingsArray = this.meetings.dataSource;
    } else {
      // If dataSource is a DataManager, convert it to an array using toArray() method
      meetingsArray = (this.meetings.dataSource as any).toArray();
    }

    // Find meetings that include the same employee(s)
    const updatedEmployeeMeetings = meetingsArray.filter(
      meeting => meeting.EmployeeId.some((id: number) => updatedEvent.EmployeeId.includes(id)) // Find meeting of the same employee
    );

    // Update the start and end time for all matching meetings
    updatedEmployeeMeetings.forEach(meeting => {
      if (meeting.Id === updatedEvent.Id) {
        // Update this meeting's start and end time based on the resized event
        meeting.StartTime = updatedEvent.StartTime;
        meeting.EndTime = updatedEvent.EndTime;
      }
    });

    //this.cd.markForCheck();
    this.cd.detectChanges();
    // Optionally, save the updated meetings data to backend here.
    console.log("Updated meetings for employees:", updatedEmployeeMeetings);
  }

  // Handle when the popup opens (to edit the event)
  public onPopupOpen(args: any): void {
    const event = args.data;
    if (event.EmployeeId && Array.isArray(event.EmployeeId)) {
      // Logic to display multiple participants in the popup
      console.log("Multiple participants:", event.EmployeeId);
    }
  }

  // Handle action begin (e.g., saving or cancelling changes)
  public onActionBegin(args: ActionEventArgs): void {
    if (args.requestType === "save") {
      // Ensure that `args.data` is of the correct type (single event)
      const eventData = args.data as { EmployeeId: number[] }; // Cast to correct type
      console.log("Saving meeting with participants:", eventData.EmployeeId);
    }
  }
}
