import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivityDto } from "@apiModels/activityDto";
import { ActivityFilterDto } from "@apiModels/activityFilterDto";
import { KanbanItemDto } from "@apiModels/kanbanItemDto";
import { KanbanItemFilterDto } from "@apiModels/kanbanItemFilterDto";
import { ProjectFilterDto } from "@apiModels/projectFilterDto";
import { ProjectTaskDto } from "@apiModels/projectTaskDto";
import { ProjectTaskNoteDto } from "@apiModels/projectTaskNoteDto";
import { ApiRoutes } from "@shared/classes/ApiRoutes";
import { map, Observable } from "rxjs";
import { DataForKanban } from "src/app/components/kanban/KanbanData";

@Injectable({ providedIn: "root" })
export class ActivityService {
  constructor(private http: HttpClient) {}

  // public getActivities(filter: ActivityFilterDto): Observable<ActivityDto[]> {
  //   const params: { [key: string]: string } = {};

  //   return this.http
  //     .get<ActivityDto[]>(ApiRoutes.activityRoutes.get.getActivities, {
  //       params: params
  //     })
  //     .pipe(
  //       map(items => {
  //         return items;
  //       })
  //     );
  // }

  // public getActivities(filter: ActivityFilterDto): Observable<ActivityDto[]> {
  //   const params: { [key: string]: string } = {};

  //   if (filter.activityAssignmentUserId && filter.activityAssignmentUserId.length > 0) {
  //     params["activityAssignmentUserId"] = filter.activityAssignmentUserId.join(",");
  //   }

  //   return this.http.get<ActivityDto[]>(ApiRoutes.activityRoutes.get.getActivities, { params }).pipe(map(items => items));
  // }

  public getActivities(filter: ActivityFilterDto): Observable<ActivityDto[]> {
    return this.http.post<ActivityDto[]>(ApiRoutes.activityRoutes.get.getActivities, filter).pipe(map(items => items));
  }

  public getActivityFromId(id: number): Observable<ActivityDto> {
    const params: { [key: string]: string } = { id: id.toString() };
    return this.http.get<ActivityDto>(ApiRoutes.activityRoutes.get.getActivityFromId, { params: params });
  }

  public updateActivity(dto: ActivityDto): Observable<any> {
    return this.http.post<any>(ApiRoutes.activityRoutes.update.updateActivity, dto);
  }

  public getProspectActivities(prospectId: number): Observable<ActivityDto[]> {
    const params: { [key: string]: string } = { prospectId: prospectId.toString() };

    return this.http
      .get<ActivityDto[]>(ApiRoutes.activityRoutes.get.getProspectActivities, {
        params: params
      })
      .pipe(
        map(items => {
          return items;
        })
      );
  }

  public getAllKanbanItems(filter: KanbanItemFilterDto): Observable<KanbanItemDto[]> {
    return this.http.post<KanbanItemDto[]>(ApiRoutes.activityRoutes.get.getAllKanbanItems, filter).pipe(map(items => items));
  }

  public getProjectTaskById(id: number): Observable<ProjectTaskDto> {
    const params: { [key: string]: string } = { id: id.toString() };
    return this.http.get<ActivityDto>(ApiRoutes.activityRoutes.get.getProjectTaskById, { params: params });
  }

  public updateTasks(tasks: DataForKanban[]): Observable<any> {
    return this.http.post(ApiRoutes.activityRoutes.update.updateTasks, tasks);
  }

  public updateProjectTask(dto: ProjectTaskDto): Observable<KanbanItemDto> {
    return this.http.post<KanbanItemDto>(ApiRoutes.activityRoutes.update.updateProjectTask, dto);
  }

  public updateProjectTaskNote(dto: ProjectTaskNoteDto): Observable<any> {
    return this.http.post<any>(ApiRoutes.activityRoutes.update.updateProjectTaskNote, dto);
  }
}
