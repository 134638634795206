<ejs-schedule
  width="100%"
  height="600px"
  [selectedDate]="selectedDate"
  [eventSettings]="meetings"
  [group]="{ resources: ['Employees'] }"
  [allowDragAndDrop]="true"
  [allowResizing]="true"
  [startHour]="startHour"
  [endHour]="endHour"
  (resize)="onEventResize($event)"
  (actionBegin)="onActionBegin($event)"
  (popupOpen)="onPopupOpen($event)"
>
  <e-views>
    <e-view option="Week"></e-view>
  </e-views>

  <!-- Define employee resources -->
  <e-resources>
    <e-resource
      field="EmployeeId"
      title="Ansat"
      name="Employees"
      [dataSource]="employeeData"
      textField="Name"
      idField="Id"
      colorField="Color"
      multiple="true"
    ></e-resource>
  </e-resources>
</ejs-schedule>
