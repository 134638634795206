<p-accordion>
  <p-accordionTab header="Filtrer">
    <div class="flex-container">

      <div class="p-field">
        <label for="ansvarlig">Ansvarlig: </label>
        <p-multiSelect optionLabel="label" dataKey="value" [options]="userFilterItems" [(ngModel)]="selectedUserItems"></p-multiSelect>
      </div>

      <div class="p-field">
        <label for="status">Status: </label>
        <p-multiSelect optionLabel="label" dataKey="value" [options]="statusFilterItems" [(ngModel)]="selectedStatusItems"></p-multiSelect>
      </div>

      <div class="p-field">
        <label for="Type">Type: </label>
        <p-multiSelect optionLabel="label" dataKey="value" [options]="farmTypeItems" [(ngModel)]="selectedFarmTypeItems"></p-multiSelect>
      </div>

      <div class="p-field">
        <label for="Mejeri">Mejeri: </label>
        <p-multiSelect optionLabel="label" dataKey="value" [options]="mejeriItems" [(ngModel)]="selectedMejeriItems"></p-multiSelect>
      </div>

      <div class="p-field">
        <label for="fraAntalKøer">Fra antal køer: </label>
        <p-inputNumber [(ngModel)]="fraKoer"></p-inputNumber>
      </div>

      <div class="p-field">
        <label for="tilAntalKøer">Til antal køer: </label>
        <p-inputNumber [(ngModel)]="tilKoer"></p-inputNumber>
      </div>

      <div class="p-field">
        <button pButton type="button" (click)="handleFilterChange()">Filtrer</button>
        <button pButton class="ml-2" type="button" (click)="resetFilter(true)">Nulstill filter</button>
        <button pButton class="ml-2" type="button" (click)="removeFilter(true)">Fjern filter</button>
      </div>
    </div>

    <p-accordion>
      <p-accordionTab header="Farveforklaring">
        <div id="colorLegend">
          <ul>
            <li><span class="legendMarker ingen telekontakt">999</span> Telekontakt</li>
            <li><span class="legendMarker ingen salgsmode">999</span> Salgsmøde</li>
            <li><span class="legendMarker ingen praktik">999</span> Praktik</li>
            <li><span class="legendMarker ingen ugyldigeoplysninger">999</span> UgyldigeOps</li>
            <li><span class="legendMarker ingen afvist">999</span> Afvist</li>
            <li><span class="legendMarker ingen kunde">999</span> Kunde</li>
            <li><span class="legendMarker magnus">999</span> Magnus</li>
            <li><span class="legendMarker glennie">999</span> Glennie</li>
            <li><span class="legendMarker johanne">999</span> Johanne</li>
            <li><span class="legendMarker nina">999</span> Nina</li>
            <li><span class="legendMarker niels">999</span> Niels</li>
          </ul>
        </div>
      </p-accordionTab>
    </p-accordion>
  </p-accordionTab>
</p-accordion>

<div id="map" style="height: 85vh"></div>

<p-dialog
  *ngIf="farmDialogVisible"
  [header]="(selectedFarm.customerId ? 'KUNDE' : 'PROSPECT') + ' - ' + selectedFarm?.brugerNavn"
  [(visible)]="farmDialogVisible"
  [modal]="true"
  (onHide)="handleCloseDialog(null)"
  [style]="{ width: '95vw', minHeight: '95vh' }"
  [maximizable]="true"
>
  <div *ngIf="selectedFarm">
    <app-prospect-detail [prospectId]="selectedFarm.id" (closeDialog)="handleCloseDialog($event)"> </app-prospect-detail>

    <!-- <div class="p-field">
      <label for="ansvarligUser">Ansvarlig bruger:</label>
      <p-dropdown optionLabel="label" dataKey="value" [options]="userItems" [(ngModel)]="farmUserItem" scrollHeight="400px" [style]="{ 'min-width': '12rem' }"></p-dropdown>

      <label for="Lifecyclestage" class="ml-2">Lead status:</label>
      <p-dropdown optionLabel="label" dataKey="value" [options]="statusFilterItems" [(ngModel)]="farmStatusItem" scrollHeight="400px" [style]="{ 'min-width': '12rem' }"></p-dropdown>

      <button class="ml-5" pButton type="button" [label]="'Gem ændringer' | translate" (click)="saveChanges()"></button>
    </div>
    <strong>Chr:</strong> {{ selectedFarm?.chrNr }} <br />
    <strong>Ejer:</strong> {{ farmUserItem?.label }} <br />
    <strong>Name:</strong> {{ selectedFarm.ejerNavn }} <br />
    <strong>Kontakt:</strong> {{ selectedFarm.contactName }} <br />
    <strong>Email:</strong> {{ selectedFarm.contactEmail }} <br />
    <strong>Telefon:</strong> {{ selectedFarm.contactPhone }} <br />
    <strong>Lifecyclestage:</strong> {{ selectedFarm.lifecyclestage }} <br />
    <strong>Køer:</strong> {{ selectedFarm.koer }} <br />
    <strong>Mejerier:</strong> {{ selectedFarm.mejerier }} <br />
    <strong>Kvier:</strong> {{ selectedFarm.kvier }} <br />
    <strong>Handyr:</strong> {{ selectedFarm.handyr }} <br />
    <strong>Adresse:</strong> {{ selectedFarm.adresse }} <br />
    <strong>Postnr:</strong> {{ selectedFarm.postNummer }} <br />
    <strong>PostDistrikt:</strong> {{ selectedFarm.postDistrikt }} <br />
    <strong>Region:</strong> {{ selectedFarm.region }} <br />

    <a
      href="https://app-eu1.hubspot.com/contacts/144491104/company/{{ selectedFarm.prospectId }}"
      (click)="openInNamedWindow($event, 'HubspotWindow')"
      target="HubspotWindow"
      class="hubspot-link"
      >Åbn i Hubspot</a
    >

    <button class="ml-5 mr-2" [disabled]="userId !== 25" pButton type="button" [label]="'Opret Email' | translate" (click)="createMail()"></button>
    <button class="ml-5" [disabled]="userId !== 25" pButton type="button" [label]="'Opret aftale' | translate" (click)="createAppointment()"></button>
 -->
  </div>
</p-dialog>
